// This may not be needed
// class PrivateHandyGolfAPI {
//     constructor() {
//         console.log('API Singleton Instantiated.');
//     }
// }
// Libraries
const HandyGolf = require('./HandyGolf');
const { v4: uuidv4 } = require('uuid');
const Cookies = require('universal-cookie');

// Configuration Constants
const API_TOKEN_HEADER_KEY = 'Handygolf-Api-Token';
const API_TOKEN_COOKIE_NAME = 'HGAPI-token';
const cookies = new Cookies();

class HandyGolfAPI {
    constructor() {
        throw new Error('Cannot be used in instantiated form.');
    }

    static AUTH_TYPE_EMAIL = 1;
    static AUTH_TYPE_GOOGLE = 2;
    static AUTH_TYPE_APPLE = 3;

    static async configure() {
        if(!HandyGolfAPI.configured) {
            HandyGolfAPI.hostname = HandyGolf.getValue('api_hostname');
            if(!HandyGolfAPI.hostname) {
                throw new Error("API Hostname is undefined.")
            }
            HandyGolfAPI.configured = true;
        }
        await HandyGolfAPI.checkToken();
    }

    static async sendRequest(method, params) {
        await HandyGolfAPI.configure();
        let headers = new Headers({
            [API_TOKEN_HEADER_KEY]: HandyGolfAPI.api_token,
            'Content-Type': 'application/json; charset=utf-8',
        })
        return fetch(
        HandyGolfAPI.hostname + '/api/' + method,
        {
                method: "POST",
                headers: headers,
                body: JSON.stringify(params)
            }
        )
            .then((response) => response.json() )
            .then((data) => {
                if(!data.status || data.status === 'error') {
                    alert(data.message);
                    // throw new Error(data.message || "An unknown error occurred.")
                }
                return data.response;
            } )
    }

    static api_token;
    static session;
    static async checkToken(reset_cache= false) {
        console.log('Checking token...');
        if (!HandyGolfAPI.api_token || reset_cache === true) {
            HandyGolfAPI.api_token = cookies.get(API_TOKEN_COOKIE_NAME);

            // @todo Await API Call to Check API Token
            let headers = new Headers({
                [API_TOKEN_HEADER_KEY]: HandyGolfAPI.api_token,
                'Content-Type': 'application/json; charset=utf-8',
            })
            const response = await fetch(
                HandyGolf.getValue('api_hostname') + '/api/user/checkToken',
                {
                    method: "POST",
                    headers: headers,
                    body: JSON.stringify({})
                }
            )
                .then((response) => response.json() )
                .then((data) => {
                    if(!data.status || data.status === 'error') {
                        alert(data.message);
                        // throw new Error(data.message || "An unknown error occurred.")
                    }
                    return data.response;
                } )
            // Store Session
            console.log('session:');
            console.log(response.session);
            HandyGolfAPI.session = response.session;
            // Check for new Token
            if(response.token && response.token.length === 36 && response.token !== HandyGolfAPI.api_token) {
                // Token has been reset
                HandyGolfAPI.api_token = response.token;
                console.log('Token has changed... setting cookie')
                const date = new Date();
                const expire_time = date.getTime() + (90 * 86400 * 1000); // 90 days from now
                date.setTime(expire_time);
                const cookie_options = {path: '/', expires: date};
                cookies.set(API_TOKEN_COOKIE_NAME, HandyGolfAPI.api_token, cookie_options)
            }
        }
    }
    static async getSession(reset_cache = false) {
        console.log('Getting Session...');
        let token_response = await HandyGolfAPI.checkToken(reset_cache);
        console.log('Check Token Response:')
        console.log(token_response);
        return HandyGolfAPI.session;
    }

    static debugAPI() {
        HandyGolfAPI.configure();
        // @todo Build Headers with Authentication
        return fetch(
            HandyGolfAPI.hostname + '/api/debug',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json; charset=utf-8',
                }),
            }
        )
    }


    // Not sure if this is needed
    // static getInstance() {
    //     if (!HandyGolfAPI.instance) {
    //         HandyGolfAPI.instance = new PrivateHandyGolfAPI();
    //     }
    //     return HandyGolfAPI.instance;
    // }
}

module.exports = HandyGolfAPI;