import React from 'react';

// Components
import PageMetaData from "../components/PageMetaData";

// Navigation
import { Link } from 'react-router-dom';

// MUI
import Typography from '@mui/material/Typography';


function HomePage() {
    return (
        <div>
            <PageMetaData
                title="Coming Soon"
            />
            <video id="hgbgvid" autoPlay loop muted playsInline>
                <source src="/vid/homebg1.mp4" type="video/mp4" />
            </video>
            <div className="body_content">
                <div style={{ color: '#ffffff', textAlign: 'center', marginTop: 90 }}>
                        <Typography variant="subtitle1" className="blackshadow" gutterBottom>The Handy Golf Beta is</Typography>
                        <Typography variant="h3" className="blackshadow" gutterBottom>Coming Soon</Typography>
                        {/*<Link className="button1" to="/">Subscribe Now</Link>*/}
                </div>
            </div>
        </div>
    );
}

export default HomePage;
