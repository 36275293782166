// import logo from './logo.svg';
// import './App.css';

import React from 'react';
import { Amplify } from 'aws-amplify';

// Handy Golf Libs
import HandyGolf from './lib/HandyGolf';
import HandyGolfAuth from "./lib/HandyGolfAuth";

// Pages
import AboutPage from "./pages/AboutPage";
import AdminPage from "./pages/AdminPage";
import AdminCourseMgmtPage from "./pages/AdminCourseMgmtPage";
import ContactPage from "./pages/ContactPage";
import CourseDetailsPage from "./pages/CourseDetailsPage";
import CoursesPage from "./pages/CoursesPage";
import DevDebuggerPage from "./pages/DevDebuggerPage";
import DevStyleGuidePage from "./pages/DevStyleGuidePage";
import HomePage from "./pages/HomePage";
import MySQLQueryRunnerPage from "./pages/MySQLQueryRunnerPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import StorePage from "./pages/StorePage";
import SupportPage from "./pages/SupportPage";
import SwingAnalyzerPage from "./pages/SwingAnalyzerPage";
import SwingDoctorPage from "./pages/SwingDoctorPage";
import SwingTrainerPage from "./pages/SwingTrainerPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import ToolsPage from "./pages/ToolsPage";
import UserProfilePage from "./pages/UserProfilePage";
import UserSignUpPage from "./pages/UserSignUpPage";

// Materials
import { Button, Drawer, Divider } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// Router
import {
    Routes,
    Route,
    NavLink,
    Link,
    BrowserRouter,
    useLocation
} from "react-router-dom";

// Google Libraries
import ReactGA from 'react-ga';
// import { Authenticator } from '@aws-amplify/ui-react';
import { GoogleOAuthProvider } from '@react-oauth/google';

// CSS
import '@aws-amplify/ui-react/styles.css';

// Amplify (I'm not sure that this is needed)
import awsExports from './aws-exports';
Amplify.configure(awsExports);

// Configure Google Analytics
let analytics_id = '';
if(HandyGolf.getValue('analytics_id') !== '') {
    analytics_id = HandyGolf.getValue('analytics_id');
    ReactGA.initialize(analytics_id);
}

function ScrollToTop() {
    // Scroll to the top of the page if the navigation is changed.
    const { pathname } = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    // States
    const [state, setState] = React.useState({
        'sidemenu': false
    });
    const [ is_authenticated, setIsAuthenticated ] = React.useState(false)
    const [ user_details, setUserDetails ] = React.useState({})

    const loadUserDetails = () => {
        console.log('loading user details');
        HandyGolfAuth.isUserAuthenticated().then((is_authenticated) => {
            setIsAuthenticated(is_authenticated);
            console.log(is_authenticated);
            if(is_authenticated) {
                HandyGolfAuth.getAuthenticatedUser().then((user_details) => {
                    console.log(user_details);
                    setUserDetails(user_details);
                });
            } else {
                setUserDetails({});
            }
        })
    }

    React.useEffect(() => {
        HandyGolfAuth.appCallback = () => { loadUserDetails(); }
        loadUserDetails();
        if(analytics_id) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, []);


    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    return (
        <GoogleOAuthProvider clientId="668398659016-osa091djv0ccu0ac06jspbg12jggvd4r.apps.googleusercontent.com">
            <BrowserRouter>
                <ScrollToTop />
                <div className="site">
                    <div className="header">
                        <div className="header_content">
                            <div className="logo">
                                <Link to="/"><img src="/img/handy-golf-logo.svg" className="handy-golf-logo" alt="Handy Golf Logo" /></Link>
                            </div>
                            <div className="header_menu">
                                <div><NavLink to="/" className="header_menu">Home</NavLink></div>
                                {/*<div><NavLink to="/courses" className="header_menu">Courses</NavLink></div>*/}
                                {/*<div><NavLink to="/about" className="header_menu">About</NavLink></div>*/}
                                {/*<div><NavLink to="/support" className="header_menu">Support</NavLink></div>*/}
                                {/*<div><NavLink to="/tools" className="header_menu">Tools</NavLink></div>*/}
                                {/*<div><NavLink to="/store" className="header_menu">Store</NavLink></div>*/}
                                {/*<div><NavLink to="/admin" className="header_menu">Admin</NavLink></div>*/}
                            </div>
                            <div className="account">
                                <Link to="/user/profile"><Button><AccountCircleIcon sx={{ color: '#0071bd', fontSize: 40 }} /></Button></Link>
                            </div>
                            <div className="hamburger">
                                <React.Fragment key="sidemenu">
                                    <Button onClick={toggleDrawer("sidemenu", true)}><MenuIcon sx={{ color: '#0071bd', fontSize: 30 }}/></Button>
                                    <Drawer
                                        anchor="left"
                                        open={state["sidemenu"]}
                                        onClose={toggleDrawer("sidemenu", false)}
                                    >
                                        <div className="sidemenu">
                                            <div><NavLink to="/" onClick={toggleDrawer("sidemenu", false)}>Home</NavLink></div>
                                            {/*<div><NavLink to="/courses" onClick={toggleDrawer("sidemenu", false)}>Courses</NavLink></div>*/}
                                            {/*<div><NavLink to="/about" onClick={toggleDrawer("sidemenu", false)}>About</NavLink></div>*/}
                                            {/*<div><NavLink to="/contact" onClick={toggleDrawer("sidemenu", false)}>Contact</NavLink></div>*/}
                                            {/*<div><NavLink to="/admin" onClick={toggleDrawer("sidemenu", false)}>Admin</NavLink></div>*/}
                                            <Divider sx={{ width: 250 }}/>
                                            <div><NavLink to="/user/profile" onClick={toggleDrawer("sidemenu", false)}>Account</NavLink></div>
                                        </div>
                                    </Drawer>
                                </React.Fragment>
                            </div>
                        </div>
                    </div>
                    <div className="body">
                        <Routes>
                            <Route exact path="/" element={ <HomePage /> } />
                            {/*<Route exact path="/about" element={ <AboutPage /> } />*/}
                            {/*<Route path="/admin" element={ <AdminPage /> }/>*/}
                            {/*<Route path="/admin/course_mgmt/:action_param?/:id_param?" element={ <AdminCourseMgmtPage /> }/>*/}
                            {/*<Route path="/contact" element={ <ContactPage /> }/>*/}
                            {/*<Route path="/course_details/:course_id?" element={ <CourseDetailsPage /> }/>*/}
                            {/*<Route path="/courses" element={ <CoursesPage /> }/>*/}
                            {/*<Route path="/dev/debug" element={ <DevDebuggerPage /> }/>*/}
                            {/*<Route path="/dev/mysql" element={ <MySQLQueryRunnerPage /> }/>*/}
                            {/*<Route path="/dev/style_guide" element={ <DevStyleGuidePage /> }/>*/}
                            <Route path="/privacy_policy" element={ <PrivacyPolicyPage /> }/>
                            {/*<Route path="/store" element={ <StorePage /> }/>*/}
                            {/*<Route path="/support" element={ <SupportPage /> }/>*/}
                            {/*<Route path="/swing_analyzer" element={ <SwingAnalyzerPage /> }/>*/}
                            {/*<Route path="/swing_doctor" element={ <SwingDoctorPage /> }/>*/}
                            {/*<Route path="/swing_trainer" element={ <SwingTrainerPage /> }/>*/}
                            <Route path="/terms_of_service" element={ <TermsOfServicePage /> }/>
                            {/*<Route path="/tools" element={ <ToolsPage /> }/>*/}
                            <Route path="/user/profile" element={ <UserProfilePage /> }/>
                            <Route path="/user/sign_up" element={ <UserSignUpPage /> }/>
                        </Routes>
                    </div>
                    <div className="footer">
                        <div className="footer_content">
                            <div className="footer_content_left">&copy; 2023 Handy Golf, All Rights Reserved.</div>
                            <div className="footer_content_right">
                                {
                                    HandyGolf.getValue("is_dev_env") ?
                                        (
                                            <>
                                                {/*<Link to="/dev/style_guide" className="footer">Style Guide</Link>*/}
                                                {/*<Link to="/dev/mysql" className="footer">MySQL</Link>*/}
                                                {/*<Link to="/dev/debug" className="footer">Debug</Link>*/}
                                            </>
                                        ) : (
                                            <></>
                                        )
                                }
                                <Link to="/privacy_policy" className="footer">Privacy Policy</Link>
                                <Link to="/terms_of_service" className="footer">Terms of Service</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        </GoogleOAuthProvider>
    );
    // return (
    //     <div>
    //       {/*<Authenticator>*/}
    //       {/*  {({ signOut, user }) => (*/}
    //       {/*      <main>*/}
    //       {/*        <h1>Hello {user.username}</h1>*/}
    //       {/*        <button onClick={signOut}>Sign out</button>*/}
    //       {/*      </main>*/}
    //       {/*  )}*/}
    //       {/*</Authenticator>*/}
    //         <div style={{width: 600, marginLeft:'auto', marginRight:'auto'}}>
    //             <CourseMgmt />
    //         </div>
    //       {/*<MySQLQueryRunnerForm />*/}
    //     </div>
    // );
}

export default App;
