import React from 'react';


// Libraries
import HandyGolfAuth from "../lib/HandyGolfAuth";
import { useGoogleLogin, GoogleLogin, googleLogout  } from '@react-oauth/google';

// MUI
import AppleIcon from '@mui/icons-material/Apple';
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";


// Nav
import { Link } from "react-router-dom";
import HandyGolfAPI from "../lib/HandyGolfAPI";

function GoogleIcon() {
    return <div style={{height:18, width:18, marginRight:15}}><svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="LgbsSe-Bz112c"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg></div>;
}


function UserProfilePage() {
    const [ loaded_auth, setLoadedAuth ] = React.useState(false)
    const [ is_authenticated, setIsAuthenticated ] = React.useState(false)
    const [ user_details, setUserDetails ] = React.useState({})
    const [ email, setEmail ] = React.useState('');
    const [ password, setPassword ] = React.useState('');
    const [ beta_code, setBetaCode ] = React.useState('');

    const tmpGoogleLogin = React.useRef(null);

    // Google Login Function
    const loginWithGoogle = useGoogleLogin({
        onSuccess: tokenResponse => console.log(tokenResponse),
    });

    const loadUserAuthenticationDetails = async (reset_cache = false) => {
        if(reset_cache) {
            await HandyGolfAuth.resetSessionCache();
        }
        let is_authenticated = HandyGolfAuth.isUserAuthenticated();
        setIsAuthenticated(is_authenticated);
        if(is_authenticated) {
            setUserDetails(await HandyGolfAuth.getAuthenticatedUser());
        }
    }
    React.useEffect(() => {
        if(!loaded_auth) {
            loadUserAuthenticationDetails()
                .then(() => {
                    setLoadedAuth(true);
                })
        }
    }, [])

    return (
        <div className="body_content">
            <div style={{maxWidth:600, minWidth:300, marginLeft:"auto", marginRight:"auto"}}>
                {
                    !loaded_auth ? (
                        <CircularProgress />
                    ) : (
                        (is_authenticated && user_details && user_details.full_name) ? (
                            <>
                                <Typography variant="h5" gutterBottom>User Profile</Typography>
                                <Typography variant="body2" gutterBottom>
                                    <strong>Name:</strong> {user_details.full_name}<br />
                                    <strong>Email:</strong> {user_details.email}
                                </Typography>
                                {/*<Typography variant="h5" gutterBottom>User Profile for {user_details.full_name}</Typography>*/}
                                {/*<Typography variant="body2" gutterBottom>*/}
                                {/*    <Card sx={{ maxWidth: 345 }}>*/}
                                {/*        <CardContent>*/}
                                {/*            <Typography gutterBottom variant="h5" component="div">*/}
                                {/*                Subscription Status*/}
                                {/*            </Typography>*/}
                                {/*            {(user_details.is_subscribed) ? (*/}
                                {/*                <Typography variant="body2" color="text.secondary">You are currently subscribed to the Handy Golf Beta program until {user_details.subscribed_until}.</Typography>*/}
                                {/*            ) : (*/}
                                {/*                <>*/}
                                {/*                    <Typography variant="body2" color="text.secondary">You are not subscribed to the beta program. If you would like to partipate in the beta, please enter a beta participation code below and click on the <strong>Subscribe to Beta</strong> button.</Typography>*/}
                                {/*                    <TextField label="Beta Code" name="beta_code" defaultValue="" onChange={(e) => setBetaCode(e.target.value)} style={{marginTop:20, marginBottom:20}} /><br />*/}
                                {/*                    <Button*/}
                                {/*                        variant="contained"*/}
                                {/*                        className="green"*/}
                                {/*                        onClick={() => {*/}
                                {/*                            HandyGolfAPI.sendRequest(*/}
                                {/*                                "user/subscribeToBeta",*/}
                                {/*                                { beta_code: beta_code }*/}
                                {/*                            )*/}
                                {/*                                .then(response => {*/}
                                {/*                                    loadUserAuthenticationDetails(true)*/}
                                {/*                                        .then(() => {*/}
                                {/*                                            // Details should be loaded at this point*/}
                                {/*                                        });*/}
                                {/*                                });*/}
                                {/*                        }}*/}
                                {/*                    >Subscribe to Beta</Button>*/}
                                {/*                </>*/}
                                {/*            )}*/}
                                {/*        </CardContent>*/}
                                {/*    </Card>*/}
                                {/*</Typography>*/}
                                <br />
                                <Button variant="contained" onClick={() => {HandyGolfAuth.logout().then(() => { setIsAuthenticated(false); setUserDetails({}); googleLogout(); })}}>Sign Out</Button>
                            </>
                        ) : (
                            <>
                                <Typography variant="h5" gutterBottom>Account Sign In</Typography>
                                <Button variant="outlined" className="google_sign_in" onClick={() => { tmpGoogleLogin.current.style.display='block'; }}><GoogleIcon />Continue with Google</Button>
                                <div ref={tmpGoogleLogin} style={{display:'none'}}>
                                    <GoogleLogin
                                        onSuccess={credentialResponse => {
                                            // console.log(credentialResponse);
                                            HandyGolfAuth.loginWithGoogleAccount(credentialResponse.credential)
                                                .then(async (data) => {
                                                    await HandyGolfAuth.resetSessionCache();
                                                    setIsAuthenticated(await HandyGolfAuth.isUserAuthenticated());
                                                    setUserDetails(await HandyGolfAuth.getAuthenticatedUser());
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                })
                                        }}
                                        onError={() => {
                                            console.log('Login Failed');
                                        }}
                                    />
                                </div>
                                <Button variant="contained" className="apple_sign_in" onClick={() => { }}><AppleIcon style={{marginRight:10}} />Continue with Apple</Button>
                                <Divider />
                                <Typography variant="h5" style={{marginTop:20}}>Sign In with Email and Password:</Typography><br />

                                <TextField label="Email" onChange={(e) => { setEmail(e.target.value)}} style={{width:'100%'}} />
                                <TextField label="Password" type="password" onChange={(e) => { setPassword(e.target.value)}} style={{width:'100%', marginTop:20, marginBottom:20}} />
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        HandyGolfAuth.loginWithEmailAccount(email, password)
                                            .then(async (data) => {
                                                await HandyGolfAuth.resetSessionCache();
                                                setIsAuthenticated(await HandyGolfAuth.isUserAuthenticated());
                                                setUserDetails(await HandyGolfAuth.getAuthenticatedUser());
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            })
                                    }}
                                    className="green"
                                    style={{width:'100%', marginBottom:20}}
                                >Login</Button><br />
                                <Link to="/user/sign_up"><Button variant="contained" style={{width:'100%'}}>Create a New Account</Button></Link>
                                {/*<Typography variant="h6" gutterBottom>Development Login as Persona:</Typography>*/}
                                {/*<Button variant="contained" onClick={() => {HandyGolfAuth.loginAsPersona('john').then(() => setLoadedAuth(false))}}>Log In as John Freeman</Button><br /><br />*/}
                                {/*<Button variant="contained" onClick={() => {HandyGolfAuth.loginAsPersona('matt').then(() => setLoadedAuth(false))}}>Log In as Matt Subscriberton</Button><br /><br />*/}
                                {/*<Button variant="contained" onClick={() => {HandyGolfAuth.loginAsPersona('dan').then(() => setLoadedAuth(false))}}>Log In as Dan Adminson</Button><br />*/}
                            </>
                        )
                    )
                }
            </div>
        </div>
    );
}

export default UserProfilePage;
