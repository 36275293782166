const React = require("react");
// const HandyGolfAPI = require("./HandyGolfAPI");

class HandyGolf {
    constructor() {
        throw new Error("The HandyGolf class should not be instantiated.")
    }

    static config_profiles = {
        localdev: {
            is_dev_env: true,
            api_hostname: "http://localhost:3001",
            analytics_id: "",
        },
        awsdev: {
            is_dev_env: true,
            api_hostname: "https://0gpdzoidl4.execute-api.us-east-2.amazonaws.com/dev",
            analytics_id: "",
        },
        beta: {
            is_dev_env: false,
            api_hostname: "https://dbh4omceti.execute-api.us-east-2.amazonaws.com/beta",
            analytics_id: "",
        },
        prod: {
            is_dev_env: false,
            api_hostname: "https://dbfzt2njsd.execute-api.us-east-2.amazonaws.com/prod",
            analytics_id: "G-MRXN3YB4HW",
        }
    };

    static getValue(key_name) {
        return HandyGolf.config_profiles[process.env.REACT_APP_HG_PROFILE][key_name];
    }

}

if(!process.env.REACT_APP_HG_PROFILE || !HandyGolf.config_profiles[process.env.REACT_APP_HG_PROFILE]) {
    throw new Error("This app has not been properly configured.")
}

module.exports = HandyGolf;