import React, { useEffect } from 'react';

// Components
import CourseMgmt from '../components/CourseMgmt';

function PageMetaData({title, seo, children}) {
    useEffect(() => {
        document.title = "HandyGolf - " + title;
        // Set SEO Meta keywords, etc...
    }, []);
    return children;
}

export default PageMetaData;
