// Libraries
const HandyGolf = require('./HandyGolf');
const HandyGolfAPI = require('../lib/HandyGolfAPI');
const Cookies = require('universal-cookie');

// Config
const api_token_cookie_name = 'HGAPI_TOKEN';
const cookies = new Cookies();
const personas = {
    john: {
        full_name: 'John Freeman',
        role: 'FREE',
    },
    matt: {
        full_name: 'Matt Subscriberton',
        role: 'SUBSCRIBER',
    },
    dan: {
        full_name: 'Dan Adminson',
        role: 'ADMIN',
    }

}

let api_token = '';
let auth_checked = false;
let is_user_authenticated = false;
let authenticated_user_details = {};


class HandyGolfAuth {
    static appCallback = null;

    static async resetSessionCache() {
        console.log('Resetting Session Cache...');
        return await HandyGolfAPI.getSession(true);
    }
    static async isUserAuthenticated() {
        console.log('is user authenticated...');
        const session = await HandyGolfAPI.getSession();
        console.log('got session for is user authenticated:');
        console.log(session);
        if(session && session.user_id) {
            return(parseInt(session.user_id) > 0);
        } else {
            return false;
        }
    }
    static async isUserAdmin() {
        const session = await HandyGolfAPI.getSession();
        return(parseInt(session.is_admin) === 1);
    }
    static async isUserSubscribed() {
        const session = await HandyGolfAPI.getSession();
        return(parseInt(session.is_subscribed) === 1);
    }
    static async getAuthenticatedUser() {
        if(await HandyGolfAuth.isUserAuthenticated()) {
            const session = await HandyGolfAPI.getSession();
            return session.user;
        } else {
            return false;
        }
    }

    static async loginAsPersona(hg_persona){
        // @todo Check for dev site and admin permissions
        if(hg_persona && personas[hg_persona]) {
            is_user_authenticated = true;
            authenticated_user_details = personas[hg_persona];
            cookies.set("HG_PERSONA", hg_persona, { path: '/' })
        }
    }
    static async loginWithGoogleAccount(credential) {
        return await HandyGolfAPI.sendRequest(
            "user/performLogin",
            {
                auth_type: HandyGolfAPI.AUTH_TYPE_GOOGLE,
                credential: credential
            }
        )
            .then(data => {
                if(HandyGolfAuth.appCallback) {
                    HandyGolfAuth.appCallback();
                }
                return true;
            })
            .catch(error => {
                throw error;
            });
    }
    static async signUpWithEmailAccount(email, full_name, password) {
        return await HandyGolfAPI.sendRequest(
            "user/createUser",
            {
                auth_type: HandyGolfAPI.AUTH_TYPE_EMAIL,
                email: email,
                full_name: full_name,
                password: password,
            }
        )
            .then(data => {
                if(HandyGolfAuth.appCallback) {
                    HandyGolfAuth.appCallback();
                }
                return true;
            })
            .catch(error => {
                throw error;
            });
    }
    static async loginWithEmailAccount(email, password) {
        return await HandyGolfAPI.sendRequest(
            "user/performLogin",
            {
                auth_type: HandyGolfAPI.AUTH_TYPE_EMAIL,
                email: email,
                password: password,
            }
        )
            .then(data => {
                if(HandyGolfAuth.appCallback) {
                    HandyGolfAuth.appCallback();
                }
                return true;
            })
            .catch(error => {
                throw error;
            });
    }
    static async logout() {
        await HandyGolfAPI.sendRequest(
            "user/performLogout",
            {}
        );
        await HandyGolfAuth.resetSessionCache();
        if(HandyGolfAuth.appCallback) {
            HandyGolfAuth.appCallback();
        }
        return true;
        // is_user_authenticated = false;
        // authenticated_user_details = {};
        // cookies.set("HG_PERSONA", "", { path: '/' })
    }
}

module.exports = HandyGolfAuth;